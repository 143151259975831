const config = {
  featureKey: 'information_common_click', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: false, // 是否使用 sessionStorage 存储数据
  // 需要收集的埋点
  saNames: [], 
  timestampLength: 10, // 时间戳长度配置
  handleAction() { // 过滤数据, 每次埋点都会触发
    return null
  },
  onTrigger({ getUserActionTracker }) {
    try {
      const advertisingActions = getUserActionTracker('advertising')?.rec_user?.['100039'] || []
      const advertisingClickAction = []
      const currentTimestamp = Math.floor(Date.now() / 1000)
      for (let i = 0; i < advertisingActions.length; i += 2) {
        if(advertisingActions[i + 1] > currentTimestamp - 60) {
          advertisingClickAction.push(advertisingActions[i], Math.floor(advertisingActions[i + 1] / 1000))
        }
      }
      const commonActions = getUserActionTracker('commonActions')?.top40ClickGoodsList?.split(',') || [] 
      const commonClickAction = []
      for (let i = 0; i < commonActions.length; i += 3) {
        commonClickAction.push(commonActions[i], Math.floor(commonActions[i + 1] / 1000))
      }
      const clickAction = advertisingClickAction.concat(commonClickAction)
      if (!clickAction?.length) return null
      return {
        rec_user: {
          100022: clickAction, // 全局点击行为（包含营销落地页的点击）
        }
      }
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
 
export default config
