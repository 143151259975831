// 营销落地页站外商品goodsid
let goodsIdData = []
const config = {
  featureKey: 'advertising', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: false, // 是否使用 sessionStorage 存储数据
  saNames: [
    {
      saName: 'click_module_goods_list',
      limit: 40
    },
    {
      saName: 'click_add_bag',
      limit: 40,
    },
  ], 
  timestampLength: 10,
  localDataConfig: {
    data: () => {
      let marketingCollect = []
      try {
        marketingCollect = JSON.parse(sessionStorage.getItem('marketingCollect')) || []
        sessionStorage.removeItem('marketingCollect')
        goodsIdData = deduplicated(JSON.parse(sessionStorage.getItem('marketingAdp')) || [])
        sessionStorage.removeItem('marketingAdp')
      } catch (e) {
        console.error(e)
      }
      return marketingCollect
    }
  },
  handleAction(data) {
    // 过滤商品点击
    const clickToDetailSaInfo = filterClick(data)
    if (clickToDetailSaInfo !== 'next') return clickToDetailSaInfo

    // 过滤加车弹窗的加车按钮点击成功
    const addCartSuccSaInfo = filterAddCart(data)
    if (addCartSuccSaInfo !== 'next') return addCartSuccSaInfo
    return null
  },
  onTrigger({ collectData, localInitData }) {
    // localInitData 为空说明刷新了页面直接返回null
    if (!localInitData?.length) {
      return null
    }
    const timestamp = Object.keys(collectData)[0] // 时间戳
    // 点击行为
    const clickAction = handleCollectData(collectData, timestamp, 'click_module_goods_list')
    // 加车行为
    const addCartAction = handleCollectData(collectData, timestamp, 'click_add_bag')
    if (!clickAction.length && !addCartAction.length) {
      return null
    }
    if (clickAction.length || addCartAction.length || goodsIdData.length) {
      return {
        rec_user: {
          100038: goodsIdData.length ? goodsIdData : undefined, // 营销落地页站外商品goodsid
          100039: clickAction.length ? clickAction : undefined, // 点击行为
          100040: addCartAction.length ? addCartAction : undefined, // 加车行为
        }
      }
    }
  }
}

const handleCollectData = (collectData, timestamp, saName) => {
  const collectInfo = collectData?.[timestamp]?.[saName]  || []
  
  const result = collectInfo.sort((a, b) => b[1] - a[1]).flat(1)
  return result
}

const filterClick = (data) => {
  const { activity_name, activity_param, timestamp, page_name } = data
  if (activity_name !== 'click_module_goods_list') return 'next'

  // 限制页面为营销落地页
  if (page_name !== 'page_ad_landing') return null

  const goods_id = activity_param?.goods_list?.split('`')?.[0] || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp)]
}
const filterAddCart = (data) => {
  const { activity_name, activity_param, timestamp, page_name } = data
  if (activity_name !== 'click_add_bag') return 'next'

  // 限制页面为营销落地页
  if (page_name !== 'page_ad_landing') return null

  const goods_id = activity_param?.goods_id || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp)]
}

// 对goods_id进行去重，并保留最近的时间戳
const deduplicated = (data) => {
  const map = new Map() // 用于存储 goods_id 和对应的最大时间戳

  for (let i = 0; i < data.length; i += 2) {
    const goods_id = data[i]
    const timestamp = Math.floor(data[i + 1] / 1000)

    // 如果 goods_id 不存在，或者当前时间戳比已存储的时间戳更大，则更新
    if (!map.has(goods_id) || map.get(goods_id) < timestamp) {
      map.set(goods_id, timestamp)
    }
  }

  // 将结果转换为扁平数组
  return Array.from(map).flat()
}
export default config
