import { asyncLoadFile, replaceHrefSpecChar, htmlDecode } from '@shein/common-function'

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { PICKS_PAGE_PATH_REG } from 'public/src/pages/product_app/router/constants.js'

/** 
 * localStorage 封装函数
 * 原shein_common 挂载在window上的全局变量, 现回归业务本身
 */
export function getWebStore() {
  const webStore = {
    storeEngine: window.localStorage,
    set (name, value) {
      this.storeEngine.setItem(name, JSON.stringify(value))
      return this
    },
    get (name) {
      return JSON.parse(this.storeEngine.getItem(name))
    },
    remove (name) {
      Array.isArray(name)
        ? name.forEach(n => this.storeEngine.removeItem(n))
        : this.storeEngine.removeItem(name)
      return this
    },
    flushAll () {
      this.storeEngine.clear()
      return this
    }
  }
  return webStore
}

/** 
 * 头部滚动固定公共函数
 * 原shein_common 挂载在window上的全局变量, 现回归业务本身
 */
export function commonHeaderScrollFix() {
  const bannerHeight = $('.c-config-banner-img').height()
  // 专题
  const $actHeader = $('.j-act-header') 
  // 闪购
  const $flashHeader = $('.j-flashsl-header')
  let $flashfilterTop = $('.j-flashsl-fix')
  $(window).bind('scroll.top_banner', () => {
    // 列表商品不满一屏不执行这里滚动事件
    // 原因：有branch banner的情况下会导致页面可以滚动
    if ($('.j-expose__content-goodsls').length && $('.j-expose__content-goodsls').length < 3) {
      return
    }

    const bodyMarginTop = parseInt($('body').css('margin-top'))
    const scrollTop = $(window).scrollTop()
    if (!$flashfilterTop.length) {
      $flashfilterTop = $('.j-flashsl-fix')
    }

    if (scrollTop > bannerHeight + bodyMarginTop) {
      $actHeader.addClass('fixed-common-header')
      $flashHeader.addClass('fixed-common-header')
      $flashfilterTop.addClass('flashsl-fix')
      return
    }

    $actHeader.removeClass('fixed-common-header')
    $flashHeader.removeClass('fixed-common-header')
    $flashfilterTop.removeClass('flashsl-fix')
  })
}

export function scrollFix() {
  const bodyEl = $('body')
  return function (isFixed, reset) {
    if (isFixed) {
      window.isBodyFixed = true
      if (window.bodyFixedCount++) {
        return
      }
      $(window).unbind('scroll.top_banner')

      // 兼容组件库滚动处理
      let top = '0px'
      if(document.body.style.position === 'fixed') {
        top = document.body.style.top
      } else {
        window.bodyFixedTop = $(window).scrollTop()
        top = -window.bodyFixedTop + 'px'
      }
     
      bodyEl.css({
        position: 'fixed',
        top
      })
    } else {
      // 防止在未打开弹窗的情况下，调用了scroll_fix(false)
      if (window.bodyFixedCount <= 0) {
        window.bodyFixedCount = 0
        return
      }
      if (reset) {
        window.bodyFixedCount = 1
      }
      if (--window.bodyFixedCount) {
        return
      }
      window.isBodyFixed = false
      commonHeaderScrollFix()

      bodyEl.css({
        position: '',
        top: ''
      })

      // 回到原先的top
      if (bodyEl.attr('data-offset-top')) {
        window.toOffSetTop = true // 解锁时，需要滚动至锁住时，记录的新的top高度
        const offsetTop = bodyEl.attr('data-offset-top') || 0
        window.scrollTo(0, offsetTop)
        $(window).scroll() // 关闭弹窗之后页面可能出现高度不够不可滚动的情况, 需要手动触发一下, 更新fixed元素计算位置
        bodyEl.removeAttr('data-offset-top')
      } else {
        $(window).scrollTop(window.bodyFixedTop)
      }
    }
  }
}

export function scrollReset() {
  if (window.bodyFixedCount) {
    const scroll_fix = scrollFix()
    scroll_fix(false, true)
  }
}

const loadedCSSList = []
export async function loadCSS (href, inBody = false) {
  if (!href || loadedCSSList.includes(href)) return
  try {
    await asyncLoadFile({
      label: 'link',
      attrs: { href, rel: 'stylesheet', type: 'text/css' },
      inBody
    })
    loadedCSSList.push(href)
  } catch (error) {
    throw new Error('load_css error: ' + href)
  }
}

/** 拼接商品详情路由 */
export function getGoodsUrl (name = '', id) {
  name = (name == '-' || !name) ? 'product' : name
  const url = ('/' + name.replace('/', '-').split(' ').join('-') + '-p-' + id + '.html')
  return replaceHrefSpecChar({ href: url })
}

export function filterAttrValue (arr = [], valueKey) {
  if (!arr?.length) return ''
  let mapLit = arr.map(item => item[valueKey])
  return mapLit.join(' / ')
}

export function robotLinkCommon (name = 'navigationBar', other = '') {
  const { langPath } = gbCommonInfo
  const page_name = window.SaPageInfo?.page_name || 'page_others'
  return `${langPath}/robot?page=${name}&page_name=${page_name}${other}`
}

export function spaJump ({ routerVm, type = 'push', url }) {
  url = url.toString()
  if (routerVm && routerVm[type] && (/\/pdsearch\//.test(url) || /\/store\/home/.test(url) || userPathObj.getTitle(url))) routerVm[type](url)
  else if (type === 'replace') location.replace(url)
  else location.href = url
}

export const queryString = {
  extract: function (str) {
    return str.split('?')[1] || ''
  },
  parse: function (str) {
    // Create an object with no prototype
    // https://github.com/sindresorhus/query-string/issues/47
    const ret = Object.create(null)

    if (typeof str !== 'string') {
      return ret
    }

    str = str.trim().replace(/^(\?|#|&)/, '')

    if (!str) {
      return ret
    }

    str.split('&').forEach(function (param) {
      const parts = param.replace(/\+/g, ' ').split('=')
      // Firefox (pre 40) decodes `%3D` to `=`
      // https://github.com/sindresorhus/query-string/pull/37
      let key = parts.shift()
      let val = parts.length > 0 ? parts.join('=') : undefined

      key = decodeURIComponent(key)

      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = val === undefined ? null : decodeURIComponent(val)

      if (ret[key] === undefined) {
        ret[key] = val
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val)
      } else {
        ret[key] = [ret[key], val]
      }
    })
    return ret
  },
  stringify: function (obj) {
    return obj
      ? Object.keys(obj)
        .sort()
        .map(function (key) {
          const val = obj[key]

          if (val === undefined) {
            return ''
          }

          if (val === null) {
            return key
          }

          if (Array.isArray(val)) {
            const result = []

            val.slice().forEach(function (val2) {
              if (val2 === undefined) {
                return
              }

              if (val2 === null) {
                result.push(key)
              } else {
                result.push(key + '=' + val2)
              }
            })

            return result.join('&')
          }

          return key + '=' + val
        })
        .filter(function (x) {
          return x.length > 0
        })
        .join('&')
      : ''
  },
  convertHref: function (obj) {
    let queryObj = this.parse(location.search)
    queryObj = $.extend({}, queryObj, obj)
    return Object.keys(queryObj).length ? location.pathname + '?' + this.stringify(queryObj) : location.pathname
  }
}

export function htmlDecodeCommonFn(text, isHtml) {
  return htmlDecode({ text, isHtml })
}

export function isLogin () {
  return UserInfoManager.isLogin()
}


export const userPathObj = {
  getTitle (url) {
    const vscReg = /([^\/]+)-[vs]?c-\d+\.html/
    const dailyReg = /\/?daily-new\.html.*daily=(\d+-\d+-\d+)/
    if (vscReg.test(url)) return url.match(vscReg)[1]
    else if (PICKS_PAGE_PATH_REG.test(url)) return url.match(PICKS_PAGE_PATH_REG)[1]
    else if (dailyReg.test(url)) return url.match(dailyReg)[1]
    else if (/\/?daily-new\.html/.test(url)) return 'whats new'
    else return ''
  },
  replaceSpeCha: str => typeof str === 'string' && str.replace(/\s*[#%&]\s*/g, '-')
}

export const getIpCountrySetCache = async (cb, onlySetCountry = false) => {
  const options = {
    reset: true
  }

  const countryData = await UserInfoManager.get({ key: 'Country', actionType: 'getIpCountrySetCache', options })

  if (!onlySetCountry) {
    window.SHEIN_LOGIN?.evt?.emit('default-country-change', countryData.formatCountryAbbr)
  }
  cb && cb(countryData)
}



/**
 * 异步加载文件
 */
export function asyncLoad({ type, src, attrs = {}, el = 'body' }) {
  return new Promise((resolve, reject) => {
    let tag = document.createElement(type)
    tag.src = src
    Object.keys(attrs).forEach(key => {
      tag.setAttribute(key, attrs[key])
    })
    if (type == 'iframe') {
      tag.style.display = 'none'
    }
    tag.onload = resolve
    tag.onerror = reject
    document[el].appendChild(tag)
  })
}