import { CollectData } from './CollectData'
import { RouterAction } from './RouterAction.js'
import { TrackingController } from './TrackingController'
import userTrackerConfig from '../config/index'

function registerUserActionTracker(configs = [], { debug = false }) {
  const trackingController = new TrackingController({ debug })

  if (window.analysisBeforeInterceptor) {
    // 埋点拦截器
    window.analysisBeforeInterceptor.use(async result => {
      try {
        trackingController.triggerCollectDataHandlers(result.data)
      } catch(e) {
        console.error(e)
      }
      if (result.data?.activity_param?._actionId) {
        delete result.data.activity_param._actionId
      }
      return result
    })
  }

  /**
   * 处理初始化本地数据
   * @return {Array} data || null
  */
  function handleLocalDataConfig(config) {
    const localDataConfig = config.localDataConfig
    if (!localDataConfig) return null
    const data = typeof localDataConfig.data === 'function' ? localDataConfig.data() : localDataConfig.data
    if (!data) return null
    if (!Array.isArray(data)) {
      debug && console.warn('localDataConfig.data is not array or function')
      return null
    }
    return data
  }

  function initUserActionTracker(configs = []) {
    function useUserAcitonTracker(config) {

      const handleAction = config.handleAction
      if (handleAction) {
        config.handleAction = (data, collectData, result) => {
          result.routerAction = routerActionInstance.getData()
          return handleAction(data, collectData, result)
        }
      }
      const collectDataInstance = new CollectData(config)
      const routerActionInstance = new RouterAction(config)
      const localInitData = handleLocalDataConfig(config)
      trackingController.registerHandler({
        key: config.featureKey,
        closeSessionStorage: config.closeSessionStorage,
        collectDataInstance,
        routerActionInstance,
        notEndNames: config.notEndNames,
        localInitData, // 初始化数据 null | Array
      })
      return { collectDataInstance, routerActionInstance, localInitData }
    }

    const instances = {}
    configs.forEach(config => {
      const { collectDataInstance, routerActionInstance, localInitData } = useUserAcitonTracker(config)
      instances[config.featureKey] = {
        // 被其他特征调用的标识, 数组格式
        _: [],
        get() {
          return config.onTrigger({
            collectData: collectDataInstance.getData(),
            routerAction: routerActionInstance.getData(),
            end: () => {
              routerActionInstance.exposeNotEndTrigger()
            },
            // 获取其他特征的数据
            // featureKey: 特征key
            // origin: 是否返回原始数据，不会触发onTrigger
            getUserActionTracker: (featureKey, { origin = false } = {}) => {
              if (featureKey === config.featureKey) {
                console.warn('can not call self')
                return null
              }
              const curInstances = instances[config.featureKey]
              if (curInstances._.includes(featureKey)) {
                // 防止循环调用，我被A调用过了，我不能再调用A
                console.warn(`当前${config.featureKey}, 我被${featureKey}调用过了，我不能再调用${featureKey}`)
                return null
              }
              const instance = instances[featureKey]
              if (!instance) return null
              // 存储一个变量，用于判断当前特征被调用过
              instance._.push(config.featureKey)
              return origin ? instance.getOrigin() : instance.get()
            },
            localInitData
          })
        },
        stop() {
          // 停止收集 但不清空数据
          collectDataInstance.stop()
          routerActionInstance.stop()
        },
        getOrigin(options) {
          return {
            collectData: collectDataInstance.getData(options),
            routerAction: routerActionInstance.getData()
          }
        },
        set(type, value) {
          if (type === 'collectData') {
            collectDataInstance.trigger(value)
          } else if (type === 'routerAction') {
            routerActionInstance.trigger(value)
          } else if (type === 'customData') {
            const isSet = collectDataInstance.triggerCustomData(value)
            if (!isSet && debug) {
              console.warn('customData is not set')
            }
          }
        },
        onceSet(value, { to, from }) {
          return collectDataInstance.onceSet(value, { to, from })
        },
        // 开始收集
        startCollect(route) { 
          if (route) {
            routerActionInstance.onStart(route.to, route.from)
          } else {
            collectDataInstance.startCollect()
          }
        },
        // 清空数据
        clear(options) {
          collectDataInstance.endCollect(options)
        },
        // 提前校验是否结束
        preCheckEnd(to) {
          routerActionInstance.exposeCheckEnd({ to })
        },
        routerActionInstance,
        collectDataInstance,
      }
    })
    return instances
  }

  const instances = initUserActionTracker(configs)
  return {
    trackingController,
    instances
  }
}

let instances = null
function createAppUserActionTracker() {
  if (typeof window === 'undefined' || gbCommonInfo?.USER_ACTION_CLOSE == '1')  {
    // SSR
    return { triggerRouterActionHandlers: () => {} }
  }
  let _userTrackerConfig = userTrackerConfig
  let isDebug = false
  try {
    isDebug = localStorage.getItem('user_action_log')
  } catch(e) {
    // 
  }
  if (isDebug) {
    // 开启调试模式时，获取配置的特征key, 如果有就只使用配置的特征key，没有就使用全部
    // 例如： localStorage.setItem('user_action_log', 'click_refresh,search_cycle')
    const debugFeatureKey = `${isDebug}`.split(',') || []
    const debugConfigs = _userTrackerConfig.filter(config => debugFeatureKey.includes(config.featureKey))
    if (debugConfigs.length) {
      _userTrackerConfig = debugConfigs
    } else {
      console.warn('debug mode is open, but not found featureKey, use all featureKey!')
    }
  }

  const { instances: currentInstances, trackingController } = registerUserActionTracker(_userTrackerConfig, { debug: isDebug })
  instances = currentInstances
  window._userActionTracker = instances
  return {
    triggerRouterActionHandlers: (to, from) => {
      // 通知所有路由实例
      try {
        trackingController.triggerRouterActionHandlers(
          {
            name: to.name,
            path: to.path,
            query: to.query,
            params: to.params
          },
          {
            name: from.name,
            path: from.path,
            query: from.query,
            params: from.params
          }
        )
      } catch(e) {
        console.error(e)
      }
    }
  }
}

/**
 * @description: 获取用户行为数据
 * @param {String} featureKey 多个特征key，用逗号分隔
 * @return {Object | null} { value, collectData, routerAction } | null
 * */
function getUserActionTrackerData(featureKey) {
  if (!instances) {
    console.error('instances is not found')
    return 
  }
  if (!featureKey) {
    console.error('featureKey is required')
    return 
  }
  const keys = featureKey.split(',')
  return keys.reduce((acc, key) => {
    const value = instances[key]?.get?.()
    if (!value) return acc
    if (!acc) {
      acc = {}
    }
    // return Object.assign({}, acc, value)
    // 判断下面有哪些重复key，如果有就合, 只会混入第一层
    const mapKey = new Map(Object.entries(acc))
    const currentKeys = Object.keys(value)
    currentKeys.forEach(k => {
      const currentItem = value[k]
      if (mapKey.has(k)) {
        acc[k] = Object.assign({}, acc[k], currentItem)
      } else {
        acc[k] = currentItem
      }
    })
    return acc
  }, null)
}

/**
 * @description: 设置用户行为数据
 * @param {String} featureKey
 */
function setUserActionTrackerData(featureKey, data) {
  if (!instances) return
  const instance = instances[featureKey]
  if (instance && data) {
    instance.set('collectData', data)
  }
}

/**
 * 设置自定义数据
 * @param {String} featureKey
 * @param {Object} data 数据
*/
function setUserActionTrackerCustomData(featureKey, data) {
  if (!instances) return
  const instance = instances[featureKey]
  if (instance && data) {
    instance.set('customData', data)
  }
}

/**
 * @description: 设置用户行为数据只使用一次, 之后数据会被清空
 * @param {String} featureKey
 * @param {Object} data 数据 
 * @param {Object} to 跳转目标 需要伪造路由
 * @param {Object} from 跳转来源 使用当前页面的路由信息即可
 * @return {string} 生成的id，用于混入数据重复时的去重
 */
function setUserActionTrackerDataOnce(featureKey, data = {}, { to, from } = {}) {
  if (!instances) {
    return
  }
  const instance = instances[featureKey]
  if (instance && data) {
    // to: { path, query, name, params } path和name必须
    return instance.onceSet(data, { to, from }) // 传递to，from时立即为开始记录，防止数据丢失
  }
}

/**
 * @description: 立即开始收集函数
 * @param {String} featureKey
 * @param {Object} to 跳转目标 需要伪造路由, { path, name }
 * @param {Object} from 跳转来源 使用当前页面的路由信息即可
 */
function handleStartCollectData(featureKey, route) {
  if (!instances) {
    return
  }
  const instance = instances[featureKey]
  if (instance) {
    instance.startCollect(route)
  }
}

/**
 * @description： 结束收集函数
 * @param {String} featureKey
 * @param {Object} options { keep: false } 默认清空数据和停止收集，keep为true时，只清空数据继续收集。
 * @return {Object} { collectData }
 */
function handleEndCollectData(featureKey, options = {}) {
  const currentInstance = instances && instances[featureKey]
  if (!currentInstance) {
    console.warn(`current featureKey: ${featureKey} is not found`)
    return null
  }
  const value = currentInstance.get()
  currentInstance.clear(options) // 清空数据
  return value
}

/**
 * @description: 提前校验是否结束，在路由跳转时调用,如果结束并执行清空操作
 * @param {Object} options.featureKey 特征key
 * @param {Object} options.to 跳转目标 需要伪造路由, { path, name
 */
function userTracekPreCheckEnd({ featureKey, to }) {
  const instance = instances?.[featureKey]
  if (!instance) return
  instance.preCheckEnd(to)
}

/**
 * @description: 特征动态配置项合并
 * @param {String} featureKey 特征key
 * @param {Object} config 动态配置项
*/
function onUserTracekConfigMerge(featureKey, config) {
  const currentInstance = instances && instances[featureKey]
  if (!currentInstance) {
    console.warn(`current featureKey: ${featureKey} is not found`)
    return null
  }
  currentInstance.collectDataInstance.onConfigMerge(config)
}

/**
 * 返回当前特征的用户收集数据和路由行为数据(不会触发onTrigger)
 * @param {String} featureKey 特征key
 * @param {Object} options 配置项
 * @param {Boolean} options.isCurrentSession 是否只返回当前session下的数据
 * @return {Object} { collectData, routerAction }
*/
function getUserActionTrackerOriginData(featureKey, { isCurrentSession = false } = {}) {
  if (typeof featureKey !== 'string') {
    throw new Error('featureKey is required')
  }
  return instances[featureKey]?.getOrigin({ isCurrentSession }) || { collectData: null, routerAction: [] }
}

/**
 * 停止收集用户行为数据, 但不清空数据
 * @param {String} featureKey 特征key
*/
function stopCollectData(featureKey) {
  if (typeof featureKey !== 'string') {
    throw new Error('featureKey is required')
  }
  const currentInstance = instances && instances[featureKey]
  currentInstance.stop()
}

export {
  createAppUserActionTracker,
  getUserActionTrackerData,
  setUserActionTrackerData,
  setUserActionTrackerDataOnce,
  handleStartCollectData,
  handleEndCollectData,
  userTracekPreCheckEnd,
  onUserTracekConfigMerge,
  getUserActionTrackerOriginData,
  stopCollectData,
  setUserActionTrackerCustomData,
}
