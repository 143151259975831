import UserKey from '../UserKey'
import schttp from 'public/src/services/schttp'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@shein/common-function'

const STORAGE_KEY = 'isPaid'

const CACHE_KEY = 'is_paid_member'

const CLIENT_STORAGE_EXPIRE = 5 * 60 * 1000

class PaidMembership extends UserKey {
  static canHandle(key) {
    return key === STORAGE_KEY
  }

  async getPrimeInfo () {
    const data = await schttp({
      url: '/user-api/prime/info',
      useBffApi: true
    })
    const { code, info } = data || {}
    if (code == 0) {
      return info || {}
    }
    return {}
  }

  isNotEmpty(obj) {
    if(typeof obj !== 'object' || obj == null) return false
    return Object.keys(obj).length > 0
  }

  async get (options = {}) {
    const noCache = options?.noCache || false
    if (!this.isLogin()) {
      removeLocalStorage(CACHE_KEY)
      return {}
    }
    let result = {}
    if(noCache !== true){
      result = getLocalStorage(CACHE_KEY) || {}
    }
    if (!this.isNotEmpty(result)) {
      result = await this.getPrimeInfo()
      this.isNotEmpty(result) && setLocalStorage({
        key: CACHE_KEY,
        value: result,
        expire: CLIENT_STORAGE_EXPIRE,
      })
    }
    return result
  }

}

export default PaidMembership

